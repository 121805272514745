/* stylelint-disable selector-type-no-unknown */

@use 'sass:color';

@import 'scss/main';
@import 'components/mixins.scss';

.chatCard {
  height: 100%;
  margin: 0;
  border-radius: 0;
  border: none;
  font-family: 'Spoqa Han Sans Neo', Arial, Helvetica, sans-serif;

  at[index],
  :global(.at-mention) {
    cursor: pointer;

    &:before {
      content: '@';
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .chatBody {
    position: relative;
    padding: 5px 4px;
    margin-bottom: 0;

    p {
      margin: 0;
      min-height: 1em;
    }

    .message {
      padding: 0 0.667rem;
    }

    // 메인 입력창.
    > .chatInputWrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 20px 20px;
      z-index: 9;
      border-top: solid 1px rgba(155, 155, 155, 20%);

      .typingView + .chatInput {
        margin-top: 0;
      }

      > .chatInput {
        // border: solid 1px rgba(155, 155, 155, 50%);
        position: relative;
        padding: 16px 16px 48px;
        border-radius: 24px;
        background-color: #f4f4f4;
        border: none;
        margin-top: 26px;
      }
    }

    // edit 입력창 공통..
    .chatInputWrapper {
      .replyOrigin {
        color: #262626;

        .rBody {
          color: #7e7e7e;
        }
      }

      > .chatInput {
        // border: solid 1px rgba(155, 155, 155, 50%);
        position: relative;

        // min-height: 128px;

        .attachmentItemWrapper {
          // min-width: 130px;
          max-width: calc(50% - 20px);
          margin-right: 10px;
        }

        .attachmentItem {
          max-width: 100%;
          padding: 10px 16px 10px 14px;
          border: 1px solid #d8d8d8;
          border-radius: 8px;
          vertical-align: top;

          &.image {
            padding: 0;
            width: 44px;
            height: 44px;
            border: none;

            .fileInfo {
              padding: 0;
              border-radius: 8px;
              border: 1px solid #d9d9d9;
              overflow: hidden;
            }

            .fileIcon {
              width: 44px;
              height: 44px;

              img {
                object-fit: cover;
                left: 0;
                top: 0;
                width: 100% !important;
                height: 100% !important;
              }
            }

            .filename,
            .fileExt {
              display: none;
            }
          }

          .fileInfo {
            padding-left: 44px;
            min-height: 31px;
          }

          .fileIcon {
            left: 0;
            top: 0;
            width: 32px;
            height: 32px;

            img {
              width: 100%;
            }
          }

          .filename {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #262626;
          }

          .fileExt {
            font-size: 12px;
            font-weight: bold;
            line-height: 14px;
            color: #9b9b9b;
          }
        }
      }

      :global(.ant-spin) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: #dddb;

        :global(.ant-spin-dot) {
          top: calc(50% - 0.5em);
        }
      }
    }

    .chatList {
      position: relative;
      max-height: 100%;

      :global(.justify-content-start),
      :global(.justify-content-end) {
        min-height: 100%;
      }

      .spin {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        margin-top: -0.5em;
      }

      > :global(.perfect-list) > :global(.scroll-content) {
        overflow-x: hidden !important;
      }

      .scrollHelper {
        padding-bottom: 8px;
      }

      &.loading {
        &:global(.firstLoading) :global(.perfect-list) {
          visibility: hidden;
        }

        :global(.perfect-list) {
          opacity: 0.4;
          pointer-events: none;
        }
      }

      .moveBtn {
        position: absolute;
        bottom: 10px;
        top: auto;
        z-index: 90;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 1s, opacity 1s;

        &.newMessage,
        &.return {
          left: 50%;
          transform: translate(-50%, 0);

          > button {
            padding: 0 44px 0 20px;
          }
        }

        &.bottom {
          right: 20px;
        }

        &.on {
          visibility: visible;
          opacity: 1;
          transition: visibility 0s, opacity 1s;
        }

        &.newMessage.on + .moveBtn,
        &.newMessage.on + .moveBtn + .moveBtn {
          visibility: hidden;
          opacity: 0;
        }

        // returnToReply가 보여질 때, 다른 버튼은 숨김.
        &.return.on + .moveBtn {
          visibility: hidden;
          opacity: 0;
        }

        button:global(.ant-btn) {
          position: relative;
          min-width: 40px;
          height: 40px;
          border-radius: 20px;
          line-height: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          color: #000;
          background-color: #e7e7e7 !important;
          border: none;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 25%) !important;

          > img {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
          }

          > * {
            vertical-align: top;

            &:nth-child(1) {
              margin-top: 0;
            }

            &:nth-child(2) {
              margin-top: 1px;
            }
          }
        }
      }
    }

    .chatList:global(.toolbarShown) {
      height: calc(100% - 109px);
    }
  }
}

:global(.archived) .chatInputWrapper {
  display: none;
}

.commentView .chatList.commentList {
  .moveBtn {
    z-index: 1000;
  }
}

.chatInput {
  // padding: 2px 1.66rem;
  background-color: #fff;
  margin-top: 1rem;
  padding: 16px 16px 38px;
  border-radius: 8px;
  border: solid 1px rgba(155, 155, 155, 50%);

  form:global(.ant-form) {
    padding: 0;
    border: 0;
    border-radius: 0;
  }

  .btns {
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 20px;
    height: 24px;

    .toolbarBtn {
      padding: 0;
      width: 18px;
      height: 18px;
      margin-right: 12px;
      background-color: transparent;

      img {
        vertical-align: top;
      }
    }

    button {
      vertical-align: middle;
    }

    .left {
      border: none;
      color: #9b9b9b;
    }

    .sendBtn {
      // background-color: #2860c5;
      // color: #fff;
      padding: 0;
      width: 24px;
      height: 24px;
      border: none;
      background-color: transparent;

      img {
        border-radius: 50%;
        background-color: #007aff;
      }

      &[disabled] img {
        background-color: #777;
      }
    }
  }

  at[index],
  :global(.at-mention) {
    color: #50e3c2;

    &:hover {
      text-decoration: none;
    }

    &[contentEditable='false'][data-mce-selected] {
      outline: none;
      background-color: lightgray;
    }
  }

  // :global(.tox-edit-area) {
  //   min-height: 24px;
  // }

  :global(.ant-form-item) {
    margin: 0;
  }

  :global(.ant-form) .toolbar {
    height: 0;
    display: block !important;
    width: 100%;

    > :global(.tox-tinymce) {
      display: none !important;
    }

    :global(.tox-autocompleter) {
      :global(.tox-collection) > :global(.tox-collection__group) {
        > :global(.tox-collection__item) {
          position: relative;
          height: 48px;

          :global(.card-avatar) {
            position: absolute;
            left: 8px;
            top: 8px;
            width: 32px;
            height: 32px;
            border-radius: 16px;
          }

          :global(.card-name) {
            padding-left: 40px;
            font-size: 13px;
            margin: 2px 0;
            @include ellipsis(1);
          }

          :global(.card-email) {
            padding-left: 40px;
            font-size: 11px;
            margin: 2px 0;
            color: #9b9b9b;
          }
        }
      }
    }

    &:global(.hide-ac) :global(.tox-autocompleter) {
      display: none;
    }
  }

  &:global(.toolbarShown) {
    :global(.ant-form) .toolbar {
      height: 40px;
      display: block !important;

      > :global(.tox-tinymce) {
        display: unset !important;
      }
    }
  }
}

.innerMsgWarning {
  // display: none;
  position: relative;
  overflow: hidden;
  height: 0;
  padding: 0 4px;
  font-size: 14px;
  color: #333;
  background-color: lightgray;
  transition: height 0.1s, padding 0.1s;

  &:global(.visible) {
    height: 24px;
    padding: 2px 4px;
  }
}

.existFileCheck {
  :global(.ant-modal-confirm-body) + :global(.ant-modal-confirm-btns) {
    display: none;
  }

  .fileInfo {
    margin-top: 10px;

    img {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }
}

.editorContainer {
  // width: calc(100% - 203px);
  // border-top: solid 1px lightgray;
  // height: 32px;
  position: relative;
  width: calc(100%);
  background-color: inherit;
  overflow: hidden;

  &:global(.focus) {
    overflow: visible;

    // :global(.mce-content-body) {
    //   background-color: #f2f1f1da;
    // }
  }

  :global(.mce-content-body) {
    // border: solid 1px lightgray;
    // position: absolute !important;
    // min-height: 32px;
    // line-height: 1.2;
    // letter-spacing: -0.5547px;

    top: auto;
    height: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: inherit;
    overflow-y: auto;
    max-height: 154px;
    padding: 4px;
    z-index: 10;
    color: #000;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    // p,
    // div:global(.nine-pg) {
    // }

    a {
      color: #007bff;
    }

    /* stylelint-disable selector-pseudo-class-parentheses-space-inside, indentation */
    &:global(.mce-content-body):not([dir='rtl'])[data-mce-placeholder]:not(
        :global(.mce-visualblocks)
      )::before {
      left: 5px;
    }
    /* stylelint-enable selector-pseudo-class-parentheses-space-inside, indentation */

    &:before {
      font-size: inherit;
      font-weight: inherit;
      font-stretch: inherit;
      font-style: inherit;
      letter-spacing: inherit;
      color: #9b9b9b !important;
    }

    &:focus {
      outline: none;
    }
  }
}

.toolbar {
  max-width: 100%;
  height: 40px;
  margin-top: 5px;

  // > :global(.tox-tinymce) {
  //   display: flex !important;
  // }

  button:global([title='Font sizes']) {
    width: 70px;
  }
}

.attachmentContainer {
  width: 100%;
  border-radius: 4px;

  &:empty {
    padding: 0;
  }

  > .attachment {
    display: block;

    &:empty {
      display: none !important;
    }
  }

  > .attachment + .time {
    display: none;
  }

  .moreMask {
    display: none;
  }
}

.attachmentItemWrapper {
  display: inline-block;
}

.attachmentItem {
  position: relative;
  border-radius: 4px;
  display: inline-block;
  padding: 10px 16px 10px 14px;
  margin-top: 10px;
  margin-right: 3px;
  max-width: 100%;
  text-align: left;
  background-color: #fff;

  :global(.cloud) {
    img:global(.storageProvider) {
      width: 14px;
    }
  }

  :global(.ant-spin-container) {
    line-height: 1;
  }

  &.isProgressing {
    opacity: 0.5;
  }

  .closeBtn:global(.ant-btn) {
    display: none;
    position: absolute;
    min-width: 18px;
    min-height: 18px;
    width: 18px;
    height: 18px;
    top: -7px;
    right: -5px;
    border: none;
    border-radius: 50%;
  }

  &.closable {
    // padding-right: 10px !important;
    margin-right: 10px;

    .closeBtn {
      display: block;
      color: #000;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .fileInfo {
    display: inline-block;
    position: relative;

    .filename {
      text-align: left;
      font-size: 14px;
      line-height: 16px;
      color: #262626;

      > span {
        display: inline-block;
        overflow: hidden;
        white-space: pre;
        vertical-align: middle;
      }

      span.front {
        text-overflow: ellipsis;
      }

      span.end {
        text-overflow: '';
      }
    }

    .fileExt {
      // display: none;
      text-align: left;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 14px;
      color: #9b9b9b;
      font-weight: bold;
    }

    .fileSize {
      display: inline-block;
      margin-left: 10px;
      font-weight: normal;
    }
  }

  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: auto;
  }

  :global(.ant-progress),
  :global(.ant-progress-outer) {
    line-height: 0;
  }

  :global(.ant-progress-outer),
  :global(.ant-progress-inner),
  :global(.ant-progress-bg) {
    border-radius: 8px;
    height: 100% !important;
    background-color: transparent;
  }

  :global(.ant-progress-bg) {
    background-color: rgba(0, 0, 0, 10%) !important;
  }

  &.icon {
    border: solid 1px #d8d8d8;

    .fileInfo {
      padding-left: 44px;
      min-height: 32px;
      width: 100%;

      .fileIcon {
        position: absolute;
        left: 0;
        top: 0;
        width: 32px;
        height: 32px;

        > svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.image {
    border: solid 1px gray;

    :global(.ant-tag) {
      border-radius: 4px;
    }

    .filename {
      display: block;
    }

    .thumbImg {
      max-width: 100px;
      max-height: 100px;
      min-width: 30px;
      min-height: 30px;
      border-radius: 0;
      object-fit: contain;
    }
  }
}

.iconMode .attachmentItem {
  max-width: 400px;

  &.image {
    // padding: 5px 5px 5px 0;
    border: solid 1px gray;
    padding: 10px 16px 7px 14px;

    .fileInfo {
      padding-left: 44px;
      min-height: 31px;
      max-width: 100%;

      .filename {
        display: block;
      }

      .fileExt {
        display: block;
        font-size: 12px;
      }

      // .fileIcon {
      //   display: none;
      // }
    }

    .thumbImg {
      position: absolute;
      left: 8px;
      top: calc(50% - 16px);
      width: 32px !important;
      height: 32px !important;
      max-width: unset;
      max-height: unset;
      min-width: unset;
      min-height: unset;
      border-radius: 4px;
    }
  }

  // .time {
  //   bottom: -6px;
  // }
}

.btns {
  margin-top: 5px;
  height: 32px;

  button {
    margin-right: 5px;
  }

  .right {
    float: right;
    margin-left: 5px;
    margin-right: 0;
  }
}

.scrollHelper {
  position: relative;
  min-height: 100%;
  overflow: visible;

  .contentWrap {
    overflow: visible;
  }
}

:global(.m-avatar) {
  font-size: 40px;
}

.item {
  padding: rem(10);
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: rem(15);

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: color.adjust($gray-1, $lightness: 2%);
  }

  .itemMenu {
    > :global(.ant-menu) {
      border-bottom: none;

      > li:global(.ant-menu-submenu) {
        padding: 0 3px;
        width: 30px;
      }
    }
  }
}

.current {
  background-color: $gray-1;

  &:hover {
    background-color: $gray-1;
  }
}

.info {
  min-width: 0;
}

.unread {
  min-width: 15px;
}

.messageWrapper {
  position: relative;
  max-width: 100%;

  background-color: transparent;
  transition: background-color 1.5s;

  &.highlight {
    background-color: #fbf4df;
    transition: background-color 1.5s;
  }

  // &:global(.lastSent) {
  //   .time {
  //     &:after {
  //       content: url('/assets/images/accessory-sent.svg');
  //       position: absolute;
  //       right: 7px;
  //       bottom: 12px;
  //     }
  //   }
  // }

  // &:not(:global(.ok)) .send .time {
  //   display: none;
  // }
  .time img {
    width: unset !important;
    height: unset !important;
  }

  &:global(.fail) .send .time {
    display: block !important;
    text-align: right;

    button {
      width: 24px;
      height: 24px;
      padding: 0;
      margin-left: 3px;
      margin-top: 4px;
    }
  }

  // &:global(.ok.lastRead),
  // &:global(.lastRead) {
  //   .time {
  //     &:after {
  //       content: url('/assets/images/accessory-seen.svg');
  //       position: absolute;
  //       right: 7px;
  //       bottom: 12px;
  //     }
  //   }
  // }

  // .messageContent {
  //   outline: solid 1px transparent;
  //   transition: outline 1s;
  //   border-radius: 2px;
  // }

  // &.highlight .messageContent {
  //   outline: solid 1px #d23b15;
  //   transition: outline-color 0.5s;
  // }
}

.message {
  // overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 8px;
  flex-shrink: 0;

  &.skipHeader {
    margin-top: 2px;

    .header {
      display: none;
    }

    .messageAvatar {
      visibility: hidden;
      height: 1px !important;
    }

    .messageContent {
      margin-top: 0;

      .content {
        margin-top: 0;
      }
    }
  }

  // &:global(.ant-dropdown-open) {
  //   background-color: #f8f8f8;
  // }

  .header {
    // height: 32px;
    // margin-bottom: 8px;
    position: absolute;
    top: 0;
    left: 49px;
    max-width: calc(100% - 135px);
    font-size: 12px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .messageContent {
    position: relative;
    text-align: right;
    font-size: 0;
    margin-top: 16px;
    max-width: min(calc(100% - 60px), 400px);

    > :global(.ant-dropdown-trigger) {
      position: relative;
      display: inline-block;
    }

    .contentWrapper {
      display: inline-block;
      position: relative;
      max-width: 100%;
    }

    .content {
      // border-radius: 5px;
      // padding: rem(6) rem(15) rem(6) rem(15);
      display: inline-block;
      position: relative;
      padding: 10px 12px;
      border-radius: 14px;
      margin-top: 3px;
      margin-right: 3px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 20px;
      letter-spacing: normal;
      color: #262626;
      background-color: #e5e6ec;
      text-align: left;
      max-width: 100%;

      :global(.htmlContent) {
        // line-height: 1.2;
        min-height: 16.8px;
        text-align: initial;
        word-break: break-word;

        * {
          white-space: normal !important;
        }

        pre {
          color: unset;
        }
      }

      img {
        max-width: 100% !important;
      }

      &:global(.only-icons) > :global(.htmlContent) > p {
        font-size: 50px;
        margin-bottom: -2px;
        line-height: 50px;
      }
    }

    .attachmentContainer {
      border-radius: 8px;

      > .attachment {
        max-width: 100%;
        display: inline-block;
      }

      &.iconMode .attachmentItem {
        display: block;
        max-width: 400px;

        &.image {
          // padding: 5px 5px 5px 0;
          border: solid 1px gray;
          padding: 10px 16px 7px 14px;

          .fileInfo {
            padding-left: 44px;
            min-height: 31px;

            .filename {
              display: block;
            }

            .fileExt {
              display: block;
              font-size: 12px;
            }

            // .fileIcon {
            //   display: none;
            // }
          }

          .thumbImg {
            position: absolute;
            left: 0;
            top: 0;
            width: 32px;
            height: 32px;
            max-width: unset;
            max-height: unset;
            min-width: unset;
            min-height: unset;
            border-radius: 4px;
          }
        }
      }

      &:not(.iconMode) > .attachment {
        + .time {
          display: flex;
        }

        > :global(.imgItem) {
          position: relative;
          display: inline-block;
          width: min(33% - 2px, 131px);
          margin-right: 2px;
          aspect-ratio: 1;
          vertical-align: top;

          > .image {
            width: 100%;
            height: 100%;

            > :global(.ant-spin-nested-loading) div:only-child {
              width: 100%;
              height: 100%;
            }
          }

          > .icon {
            width: 100%;
            aspect-ratio: 1;
            justify-content: center;
            align-items: center;

            // padding: 5px;
            // display: flex;

            > div {
              max-width: 100%;
            }

            .fileInfo {
              max-width: 100%;
              min-width: 100px;

              .filename,
              .fileExt {
                white-space: nowrap;
              }

              img {
                width: 100%;
                height: 100%;
                aspect-ratio: 1 !important;
                object-fit: cover !important;
              }
            }
          }

          // .time {
          //   display: none;
          // }

          img {
            width: 100%;
            height: 100%;
            aspect-ratio: 1;
            object-fit: cover;
          }

          .moreMask {
            display: block;
            position: absolute;
            left: 0;
            top: 2px;
            width: 100%;
            height: calc(100% - 2px);
            background-color: rgba(0, 0, 0, 50%);
            border-radius: 8px;

            img {
              position: absolute;
              left: calc(50% - 12px);
              top: calc(50% - 28px);
              width: 24px;
              height: 24px;
              text-align: center;
            }

            .moreText {
              position: absolute;
              left: 0;
              top: calc(50% + 2px);
              width: 100%;
              height: 24px;
              display: inline-block;
              text-align: center;
              color: #fff;
              font-size: 14px;
              line-height: 14px;
            }
          }
        }

        > :global(.imgItem):first-child:nth-last-child(1) {
          width: min(100%, 300px);
          aspect-ratio: unset;

          > .icon {
            width: 100%;
            aspect-ratio: unset;
          }

          img {
            max-width: 100%;
            width: auto;
            height: auto;
            aspect-ratio: unset;
            object-fit: contain;
          }
        }

        > :global(.imgItem):first-child:nth-last-child(2),
        > :global(.imgItem):first-child:nth-last-child(2) ~ :global(.imgItem) {
          width: min(50% - 2px, 198px);
          margin-right: 2px;
        }

        > :global(.imgItem):first-child:nth-last-child(3),
        > :global(.imgItem):first-child:nth-last-child(3) ~ :global(.imgItem) {
          width: min(33% - 2px, 131px);
          margin-right: 2px;
        }

        > :global(.imgItem):first-child:nth-last-child(4),
        > :global(.imgItem):first-child:nth-last-child(4) ~ :global(.imgItem) {
          width: min(50% - 2px, 198px);
          margin-right: 2px;
        }

        > :global(.imgItem):first-child:nth-last-child(5),
        > :global(.imgItem):first-child:nth-last-child(5) ~ :global(.imgItem) {
          width: min(33% - 2px, 131px);
          margin-right: 2px;
        }

        // > :global(.imgItem):nth-child(3):nth-last-child(3),
        > :global(.imgItem):nth-child(3):nth-last-child(3) ~ :global(.imgItem) {
          width: min(50% - 2px, 198px);
          margin-right: 2px;
        }

        > :global(.imgItem):first-child:nth-last-child(6),
        > :global(.imgItem):first-child:nth-last-child(6) ~ :global(.imgItem) {
          width: min(33% - 2px, 131px);
          margin-right: 2px;
        }

        > :global(.imgItem):first-child:nth-last-child(7),
        > :global(.imgItem):first-child:nth-last-child(7) ~ :global(.imgItem) {
          width: min(33% - 2px, 131px);
          margin-right: 2px;
        }

        // > :global(.imgItem):nth-child(3):nth-last-child(5),
        > :global(.imgItem):nth-child(3):nth-last-child(5) ~ :global(.imgItem) {
          width: min(50% - 2px, 198px);
          margin-right: 2px;
        }

        > :global(.imgItem):first-child:nth-last-child(8),
        > :global(.imgItem):first-child:nth-last-child(8) ~ :global(.imgItem) {
          width: min(33% - 2px, 131px);
          margin-right: 2px;
        }

        // > :global(.imgItem):nth-child(6):nth-last-child(3),
        > :global(.imgItem):nth-child(6):nth-last-child(3) ~ :global(.imgItem) {
          width: min(50% - 2px, 198px);
          margin-right: 2px;
        }

        > :global(.imgItem):first-child:nth-last-child(9),
        > :global(.imgItem):first-child:nth-last-child(9) ~ :global(.imgItem) {
          width: min(33% - 2px, 131px);
          margin-right: 2px;
        }

        > :global(.imgItem):nth-child(9) ~ :global(.imgItem) {
          display: none;
        }
      }

      .attachmentItemWrapper {
        display: block;
      }

      .attachmentItem {
        position: relative;
        margin-top: 2px;
        border-radius: 8px;

        .progress {
          display: none;
        }

        .fileInfo {
          .filename {
            font-size: 14px;
            word-wrap: break-word;
            word-break: break-word;
          }

          .fileExt {
            display: block;
            font-size: 12px;
          }
        }

        &.icon {
          .fileInfo {
            padding-left: 44px;
            min-height: 31px;

            .fileIcon {
              left: 0;
              top: 0;
              width: 32px;
              height: 32px;
            }
          }
        }

        &.image {
          border: none;
          padding: 0;

          .thumbImg {
            border: solid 1px lightgray;
            border-radius: 8px;
            max-width: min(300px, 100%);
            max-height: 300px;
            min-width: 30px;
            min-height: 30px;
          }

          .fileInfo {
            .filename {
              display: none;
            }

            .fileExt {
              display: none;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .time {
    position: absolute;
    top: auto;
    bottom: 1px;
    left: auto;
    right: auto;
    width: 75px;
    height: 30px;
    white-space: nowrap;
    text-align: center;
    padding: 0 8px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #605d5d;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &.statusShown .status {
    display: block;
  }

  .status {
    display: none;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    vertical-align: top;
    font-size: 12px;
    line-height: 15px;
    margin-top: 2px;

    .commentCount {
      display: inline-block;
      color: #605d5d;
      background-color: #e9f3ff;
      border: 1px solid #bed7f3;
      border-radius: 24px;
      padding: 3px 8px;
      gap: 1px;
      line-height: 15px;
      height: 22px;

      img {
        margin-top: -3px;
        width: 14px;
        height: 14px;
      }
    }

    .statusTxt {
      display: none;
      margin: 0 10px;
      color: #dd8c21;
      line-height: normal;
      font-size: 15px;
      font-weight: 500;
    }

    .reactions {
      display: inline-block;
      margin-right: 5px;
      line-height: 16px;
      vertical-align: top;

      .reactionIcon {
        display: inline-block;
        color: #605d5d;
        background-color: #e9f3ff;
        border: 1px solid #bed7f3;
        border-radius: 24px;
        padding: 3px 8px;
        gap: 1px;
        margin-right: 8px;
        height: 22px;
      }

      .reactionIcon:last-child {
        margin-right: 0;
      }
    }
  }

  &.answer {
    flex-direction: row-reverse;

    .messageAvatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-left: 0;
      margin-right: 8px;
      background-color: #d9d9d9;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .messageContent {
      // padding-right: 60px;
      text-align: left;
      max-width: min(calc(100% - 115px), 400px);

      .contentWrapper:global(.user-mention) {
        .content:after {
          content: '@';
          position: absolute;
          top: -5px;
          right: -14px;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          text-align: center;
          padding-top: 2px;
          color: #fff;
          background-color: #ff7a30;
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 14px;
        }

        .content {
          // overflow: hidden;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 14px;
            width: 100%;
            background: linear-gradient(#ff7a30 3px, #0000 3px);
            border-top-left-radius: 0;
            border-top-right-radius: 14px;
          }
        }
      }

      .content {
        // overflow: hidden;
        border-top-left-radius: 0;

        at[index],
        :global(.at-mention) {
          color: #009bda;

          &:global(.user) {
            color: #007aff;
            font-weight: bold;

            &:before {
              content: '@';
            }
          }
        }
      }

      .time {
        text-align: left;
        right: -72px;
      }
    }

    &.skipHeader {
      .messageContent {
        .content {
          border-top-left-radius: 16px;
        }

        .contentWrapper:global(.user-mention) {
          .content:before {
            border-top-left-radius: 14px;
          }
        }
      }
    }
  }

  &.send {
    padding-left: 48px !important;

    .header {
      display: none;
    }

    .messageContent {
      // padding-left: 59px;
      margin-top: 0;

      .content {
        // border-top-right-radius: 0;
        position: relative;
        text-align: right;
        color: #fff;
        background-color: #007aff;

        &::before {
          content: url('/assets/images/speech_bubble_tail.svg');
          position: absolute;
          right: -3px;
          top: -3px;
          width: 14px;
          height: 21px;
        }

        a {
          color: #ccc;

          &:hover {
            color: #aaa;
          }
        }

        at[index],
        :global(.at-mention) {
          color: #50e3c2;
        }
      }

      .time {
        text-align: right;
        left: -75px;
      }

      .attachmentContainer {
        text-align: right;
      }
    }

    &.skipHeader {
      .messageContent {
        .content {
          // border-top-right-radius: 14px;
          &::before {
            content: unset;
          }
        }
      }
    }
  }

  &.emptyText .content {
    display: none;
  }

  &.date {
    justify-content: center;

    .dateContent {
      margin: 16px 0;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #949494;
    }
  }

  &.deleted {
    display: none;

    &.justDeleted {
      display: flex;
    }

    &.ws {
      display: flex;

      .status {
        display: block;
      }

      .time {
        display: none;
      }
    }

    // .messageContent * {
    //   background-color: red;
    //   text-decoration: line-through;
    //   opacity: 0.3;
    // }

    // .attachmentContainer {
    //   opacity: 0.5;

    //   :global(.ant-tag) span {
    //     text-decoration: line-through;
    //   }
    // }

    .messageContent {
      // 시간을 표시하지 않으므로, 60px를 뺄 필요없음.
      max-width: min(100%, 400px);

      .statusTxt {
        display: inline-block;
        color: #ff2a26;
      }
    }
  }

  &.updated {
    .messageContent {
      .statusTxt {
        display: inline-block;
      }
    }
  }

  // &.statusShown {
  //   .content + .time,
  //   .content + * + .time {
  //     bottom: 27px;
  //   }
  // }
}

// :global(.selected) > .message .commentCount {
// :global(.selected) > .message .messageContent:not(.deleted) {
//   border: solid 1px gray;
// }

.messageAvatar {
  flex-shrink: 0;
  margin-left: rem(15);
}

.mail {
  span:global(.mail) {
    border: solid 1px blue;
    border-radius: 4px;
    padding: 4px;
  }
}

.mailPopup {
  max-width: 80%;
  width: 800px;

  :global(.ant-modal-confirm-content) {
    max-height: 70vh;
    height: 600px;
    padding: 20px 0;
    overflow: auto;
  }
}

// dark theme
// [data-kit-theme='dark'] {
//   .messageContent {
//     background-color: $dark-gray-5;

//     &:before {
//       border-left-color: $dark-gray-5;
//     }
//   }

//   .message {
//     &.answer {
//       .messageContent {
//         &:before {
//           border-right-color: $dark-gray-5;
//         }
//       }
//     }
//   }

//   .item {
//     &:hover,
//     &.current {
//       background-color: $dark-gray-4;
//     }
//   }
// }

.chatMember {
  max-height: 100%;
  height: unset;
  background-color: #fff;
  transition: max-height 1.2s;

  &.fold {
    max-height: 82px;

    .memberList {
      height: 0 !important;
    }
  }

  .memberList {
    position: relative;
    height: auto;
    transition: height 1.2s;
    max-height: calc(100% - 82px);
  }

  .title {
    // height: 48px;
    padding: 8px 12px;
    font-size: 18px;
    line-height: 32px;
    font-weight: bold;
    vertical-align: middle;

    .leftBox {
      float: left;
    }

    .rightBox {
      text-align: right;

      button {
        margin-left: 5px;
      }
    }

    .backBtn {
      margin-left: 0;
      margin-right: 5px;
      border: none;
    }
  }

  .foldBtn {
    border-bottom: double 3px gray;
    text-align: center;

    svg {
      width: 24px;
      height: 24px;
      transform: scale(2, 1);
    }
  }
}

.memberList {
  .userMark {
    margin-right: 5px;
    color: red;
  }

  // .owner {
  //   border: solid 1px lightgray;
  //   border-radius: 4px;
  // }

  :global(.kit__utils__avatar) {
    border-radius: 23px;
    background-color: transparent;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  :global(.perfect-list) {
    height: 100%;
  }
}

.commentList {
  .scrollHelper {
    height: auto !important;
    min-height: auto !important;
  }
}

.floatingContainer {
  position: absolute;
  z-index: 1040;

  :global(.floatingMenu) {
    top: 0 !important;
  }
}

:global(.floatingMenu.reactionMenu) {
  min-width: 263px;
}

.messageWrapper > .message {
  &.answer {
    .floatingContainer {
      top: -20px;
      left: calc(100% + 5px);

      :global(.floatingMenu) {
        left: 0 !important;
      }
    }
  }

  &.answer.skipHeader {
    .floatingContainer {
      top: -18px;
    }
  }

  &.send {
    .floatingContainer {
      top: 2px;
      right: calc(100% + 5px);

      :global(.floatingMenu) {
        left: auto !important;
        right: 0;
      }
    }

    .content > .floatingContainer {
      top: 0;
    }
  }

  &.send.skipHeader {
    .floatingContainer {
      top: 0;
    }
  }
}

:global(.floatingMenu) {
  > :global(.ant-dropdown-menu) {
    display: inline-block;
    border-radius: 8px;
    padding: 8px 10px;
    border: solid 1px #d7d7d7;
    word-break: keep-all;
    white-space: nowrap;

    > li {
      width: 24px;
    }
  }

  :global(.ant-dropdown-menu-item) {
    display: inline-block;
    line-height: 16px;
    padding: 0;
    border-radius: 4px;
    vertical-align: middle;
    margin-right: 10px;

    &:hover {
      background-color: #fbfbfb;
    }

    &:last-child {
      margin-right: 0;
    }

    .floatingItemWrap {
      margin: 0;
      padding: 4px;

      .reactionItem {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        width: 16px;
        height: 16px;
      }

      img {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }

  :global(.ant-dropdown-menu-title-content) {
    font-size: 16px;
    vertical-align: middle;
  }

  :global(.ant-dropdown-menu-submenu) {
    display: inline-block;
    line-height: 20px;
    border-radius: 4px;
    margin-right: 10px;

    &:hover {
      background-color: #fbfbfb;
    }

    &:last-child {
      margin-right: 0;
    }

    :global(.ant-dropdown-menu-submenu-title) {
      padding: 0;
      line-height: 16px;

      .floatingItemWrap {
        margin: 0;
        padding: 4px;

        .reactionItem {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          width: 16px;
          height: 16px;
        }

        img {
          width: 16px !important;
          height: 16px !important;
        }
      }

      :global(.ant-dropdown-menu-submenu-expand-icon) {
        display: none;
      }
    }
  }

  :global(.anticon) {
    text-align: center;
    vertical-align: middle;
    padding: 2px 0;
    margin: 0;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .reactionItem:global(.selected) {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #3478f6;
      border-radius: 1px;
    }
  }
}

:global(.floatingSubMenu) {
  :global(.ant-menu-sub) {
    border-radius: 8px;

    :global(.ant-menu-item) {
      padding: 6px 25px;
    }
  }

  :global(.ant-dropdown-menu-sub) {
    border-radius: 8px;

    :global(.ant-dropdown-menu-item) {
      padding: 6px 25px;
    }
  }
}

:global(.menuStyle1) {
  :global(.ant-menu.ant-menu-sub) {
    overflow: hidden;
    border-radius: 8px;
    padding: 12px 0;
    min-width: 240px;
    border: solid 1px #d7d7d7;

    :global(.ant-menu-item-divider) {
      margin: 12px 0;
      background-color: #d7d7d7;
    }

    > :global(.ant-menu-item) {
      padding: 7px 24px;
      font-size: 14px;
      height: auto;
      line-height: 22px;

      &:not(:global(.ant-menu-item-disabled)) {
        color: #262626;
      }

      &:hover {
        background-color: #fbfbfb;
      }

      > img:first-child {
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }

      img + :global(.ant-menu-title-content) {
        margin-right: 4px;
      }
    }
  }

  :global(.ant-dropdown-menu) {
    overflow: hidden;
    border-radius: 8px;
    padding: 12px 0;
    min-width: 240px;
    border: solid 1px #d7d7d7;

    :global(.ant-dropdown-menu-item-divider) {
      margin: 12px 0;
      background-color: #d7d7d7;
    }

    > :global(.ant-dropdown-menu-item) {
      padding: 7px 24px;
      font-size: 14px;

      &:not(:global(.ant-dropdown-menu-item-disabled)) {
        color: #262626;
      }

      &:hover {
        background-color: #fbfbfb;
      }

      > img:first-child {
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }

      img + :global(.ant-dropdown-menu-title-content) {
        margin-right: 4px;
      }
    }
  }
}

.linkPreview {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-height: 6.4rem;
  height: 6.4rem;
  margin: 0.4rem 0;
  text-align: left;
  font-size: 15px;

  &:global(.imgHidden) {
    .previewThumb {
      display: none;
    }
  }

  .removeBtn {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    border: none;
    background-color: transparent !important;
  }

  // &:not([type='website']) {
  //   display: none;
  // }

  &:global(.hideLink) {
    width: 100% !important;
    height: 0 !important;

    > a {
      display: none !important;
    }
  }

  > a {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    background-color: #f5f5f5;
    height: 100%;
  }

  .previewThumb {
    flex-basis: auto;
    flex-grow: 0;
    max-width: 8rem;
    min-width: 8rem;
    bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    flex-grow: 2.5;
    justify-content: space-between;
    padding: 0.53rem 0.8rem;
    font-size: 100%;

    .title {
      color: #242424;
      font-size: 14px;
      line-height: 1.4286;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      > span {
        @include ellipsis(1);
      }
    }

    > p {
      height: auto;
      color: #242424;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      min-height: 32px;

      > span {
        @include ellipsis(2);
      }
    }

    .urlInfo {
      margin-top: auto;
      font-size: 12px;
      color: #616161;
      line-height: 16px;
      display: flex;
      align-items: center;

      > span {
        @include ellipsis(2);
      }
    }
  }
}

.messageContent .linkPreview {
  display: inline-block;
  width: 228px;
  height: 220px;
  font-size: 12px;
  max-height: unset;
  margin-top: 2px;
  margin-bottom: 0;
  overflow: visible;

  &:global(.imgHidden) {
    height: 100px;

    .previewThumb {
      display: none;
    }
  }

  > a {
    display: inline-block;
    border-radius: 14px;
    border: 1px solid #ececec;
    max-width: min(100%, 228px);
    width: 228px;
    min-height: 100px;
    overflow: hidden;
    text-align: left;
  }

  .previewThumb {
    max-width: unset;
    max-height: 120px;
    height: 120px;
  }

  .info {
    padding: 10px 12px;
    height: 99px;

    > .title {
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #000;
    }

    > .title + p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #000;
    }

    .urlInfo {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 17px;
      margin-top: 0;
      color: #949494;
    }
  }
}

.send .messageContent .linkPreview {
  text-align: right;
  margin-right: 3px;

  > a {
    margin-right: 3px;
  }
}

.commentView .linkPreview {
  max-width: max(100%, 220px);
}

.commentView .chatInput .linkPreview {
  max-width: 320px;
  font-size: 13px;

  .previewThumb {
    width: 56px;
    height: 56px;
  }

  .info {
    padding: 8px;

    > span {
      margin-left: 64px;
    }

    > .title {
      margin: 10px 0 15px 64px;
    }
  }
}

.typingView {
  position: relative;
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 0 10px;
  height: 24px;

  .typingNames {
    position: relative;
    display: block;
    align-items: center;
    top: 0;
    min-width: 100%;
    min-height: 30px;
    overflow: hidden;
    transition: top 0.5s;

    > * {
      float: left;
      line-height: 30px;
      vertical-align: middle;

      // display: inline-block;
    }
  }

  &.empty {
    // visibility: hidden;
    // margin-top: 0;
    // margin-bottom: 0;
    // height: 0;

    .typingNames {
      top: calc(100% + 4px);
    }
  }

  .icons {
    display: inline-block;
    position: relative;

    img {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      overflow: hidden;

      &:nth-child(n + 2) {
        margin-left: -15px;
      }
    }
  }

  .names {
    margin-left: 5px;
    max-width: calc(100% - 100px);
    @include ellipsis(1);

    > * {
      vertical-align: middle;
    }

    .name {
      display: inline-block;

      > span {
        max-width: 80px;
        @include ellipsis(1);
      }
    }

    .comma {
      display: inline-block;
    }
  }
}

.message.system {
  justify-content: center;

  .systemContent {
    position: relative;
    text-align: center;
    width: 100%;

    :global(.systemMsgWrapper) {
      position: relative;
      text-align: center;

      * {
        white-space: normal;
        word-wrap: break-word;
      }
    }

    :global(.systemMsg) {
      max-width: 95%;
      display: inline-block;
      padding: 6px 16px;
      border-radius: 18px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      vertical-align: top;
      color: #646464;

      :global(.nameWrap) {
        display: inline-block;
        vertical-align: top;
        max-width: 200px;

        // @include respond-to('tablet') {
        //   max-width: min(20vw, 200px);
        // }

        @include respond-to('mobile') {
          max-width: min(33vw, 150px);
        }
      }

      :global(.name) {
        vertical-align: top;
        font-weight: 700;
        @include ellipsis(1);
      }

      &:global(.enter) {
        border: 1px solid #d9d9d9;
      }

      &:global(.leave) {
        background-color: #e6e6e6;
      }

      &:global(.convertRoom),
      &:global(.updateRoom) {
        border: 1px solid #d9d9d9;
      }

      &:global(.updateFile) {
        border: 1px solid #d9d9d9;
      }
    }

    :global(.systemMsgWrapper) + :global(.systemMsgWrapper) {
      margin-top: 8px;
    }

    .time {
      left: 100%;
      right: auto;
    }
  }
}

.mediaPopup:global(.ant-modal-wrap) {
  &.image.viewer {
    > :global(.ant-modal) {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }

  :global(.ant-modal) {
    padding: 0;
    width: auto !important;
    min-width: 220px;
  }

  :global(.ant-modal-content) {
    background-color: #fff;
    border-radius: 32px;
    overflow: hidden;
  }

  :global(.ant-modal-body) {
    position: relative;
    padding: 48px 0 0;
    min-height: 220px;
  }

  :global(.ant-modal-confirm-body) {
    margin-top: 0;

    :global(.ant-modal-confirm-title) {
      position: absolute;
      left: 0;
      right: 48px;
      top: 0;
      height: 48px;
    }

    :global(.ant-modal-confirm-content) {
      margin-top: 0;
    }

    :global(.ant-progress-circle.ant-progress-status-success .ant-progress-text) {
      color: #448ef7;
    }
  }

  :global(.ant-modal-confirm-btns) {
    display: none;
  }

  :global(.ant-modal-close) {
    top: 16px;
    right: 16px;

    :global(.ant-modal-close-x) {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }

  .topBtns {
    text-align: right;
    line-height: 48px;
    vertical-align: middle;

    button {
      margin: 0 10px;
      border: none;
    }
  }

  .content {
    max-width: 90vw;
    max-height: 90vh;
    min-width: 200px;
    min-height: 100px;
    width: auto;
    height: auto;
    text-align: center;
    overflow: hidden;

    :global(.ant-progress) {
      position: relative;
      padding: 2px;

      // top: calc(50% - 60px);
      // left: calc(50% - 60px);
    }

    &:global(.spinning) :global(.ant-progress) {
      visibility: hidden;
    }

    :global(.ant-spin-text) {
      padding-top: 16px !important;
    }

    .scaleWrapper {
      display: inline-block;
      text-align: left;
      overflow: hidden;

      img {
        transform-origin: 0 0;
      }
    }

    video {
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.emojiPopup {
  :global(.ant-modal-content) {
    background-color: transparent;
  }
}

.menuRoomCreate {
  :global(.ant-dropdown-menu-item) {
    color: #000;
  }
}

:global(.ant-tabs.contentList) {
  :global(.ant-tabs-nav) {
    margin: 0 0 4px;

    &:before {
      border-bottom: solid 1px #dfdfdf;
    }

    :global(.ant-tabs-tab) {
      padding: 9px 8px;
      font-size: 14px;
      font-weight: bold;

      :global(.ant-tabs-tab-btn) {
        color: #262626;
      }
    }

    :global(.ant-tabs-ink-bar) {
      height: 2px;
      border-radius: 1px;
    }
  }

  :global(.ant-tabs-content) {
    height: 100%;

    :global(.ant-tabs-tabpane) {
      height: 100%;
    }
  }
}

.popupBase {
  :global(.ant-modal) {
    min-width: min(540px, 100%);
    max-width: min(540px, 100%);
  }

  &:global(.w460) :global(.ant-modal) {
    min-width: min(460px, 100%);
    max-width: min(460px, 100%);
  }

  &.noBtn :global(.ant-modal-confirm-btns) {
    display: none;
  }

  .mb10 {
    margin-bottom: 10px !important;
  }

  .mb20 {
    margin-bottom: 20px !important;
  }

  :global(.ant-btn),
  :global(.ant-form-item),
  :global(.ant-radio-wrapper) {
    color: #262626;
  }

  :global(.ant-form-item) {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :global(.allformItems) {
    margin-bottom: -10px;
  }

  :global(.ant-btn) {
    &:global(.ant-btn-primary) {
      color: #fff;
    }

    &[disabled] {
      color: rgba(0, 0, 0, 25%);
      border: none !important;
      background: #f5f5f5 !important;
    }
  }

  .selectRole {
    display: none;

    :global(.ant-btn) {
      height: auto;
      padding: 3px 15px;
      border: 1px solid #e4e9f0;
      border-radius: 8px;
    }

    .desc {
      margin-top: 10px;
      font-size: 12px;
      padding: 0 10px;
    }
  }

  .alignRight {
    text-align: right;
  }

  .subTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin-bottom: 10px;
  }

  :global(.ant-form-item) + .subTitle {
    margin: 24px 0 20px;
  }

  :global(.ant-modal-close-x) {
    margin-top: 14px;
    margin-right: 8px;
    width: 48px;
    height: 48px;

    :global(.anticon) {
      vertical-align: top;
      margin-top: 16px;
    }
  }

  :global(.ant-modal-confirm-body) {
    :global(.anticon) {
      position: absolute;
      font-size: 24px;
      top: 18px;
    }

    :global(.ant-modal-confirm-title) {
      display: flex;
      align-items: center;
      height: 52px;
      padding-bottom: 16px;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      color: #000;

      // border-bottom: solid 1px #ededed;
      :global(.count) {
        font-size: 16px;
        font-weight: bold;
        color: #646464;
        margin-left: 10px;
      }
    }

    :global(.anticon) + :global(.ant-modal-confirm-title) {
      padding-left: 32px;
    }

    :global(.ant-modal-confirm-content) {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #000;
      margin-top: 0;

      // max-height: 488px;
    }

    :global(.anticon) + :global(.ant-modal-confirm-title) + :global(.ant-modal-confirm-content) {
      margin-left: 0;
    }

    :global(.ant-tabs-nav) {
      :global(.ant-tabs-nav-list) {
        width: 100%;

        > div:first-child:nth-last-child(2),
        > div:first-child:nth-last-child(2) ~ div {
          width: 100% !important;
        }

        > div:first-child:nth-last-child(3),
        > div:first-child:nth-last-child(3) ~ div {
          width: 50% !important;
        }

        > div:first-child:nth-last-child(4),
        > div:first-child:nth-last-child(4) ~ div {
          width: 33% !important;
        }

        > div:first-child:nth-last-child(5),
        > div:first-child:nth-last-child(5) ~ div {
          width: 25% !important;
        }
      }

      :global(.ant-tabs-tab) {
        position: relative;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000;
        margin-left: 0;
        margin-right: 0 !important;
        justify-content: center;

        > div {
          max-width: 90%;
          white-space: normal;
          @include ellipsis(1);
        }
      }
    }

    :global(.ant-tabs-tabpane.ant-tabs-tabpane-active) {
      max-height: max(50vh, 300px);
      overflow: auto;
    }

    .alarmForm {
      padding: 0;

      label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000;
        margin-bottom: 10px;

        > img {
          margin-right: 12px;
        }
      }
    }
  }

  :global(.ant-form-vertical .ant-form-item-label) {
    padding: 0;
    margin-top: 10px;
    margin-bottom: 4px;
  }

  :global(.ant-form-item-label) > label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #262626;
  }

  .itemDesc {
    font-size: 12px;
    color: #979797;
  }

  :global(.ant-form-item-control) {
    input,
    textarea {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #262626;
      background-color: #fcfcfc;
      border: solid 1px #d6d6d6;
      border-radius: 8px;
      padding: 4px 10px;

      ::placeholder {
        color: #a3a3a3;
      }

      &:focus {
        background-color: #fff;
      }
    }

    input {
      height: 36px;
    }

    textarea {
      height: 36px;
      line-height: 18px;
      padding: 8px 11px;
    }

    :global(.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked) {
      color: #9b97a9;
      background-color: #b6b6b6;
    }

    :global(.ant-form-item-control-input) {
      min-height: unset;
    }
  }

  :global(.ant-input-search) {
    background: #fbfbfb;
    border-radius: 8px;
    margin-top: 0;
    margin-bottom: 10px;

    input {
      height: 36px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #a3a3a3;
      background-color: transparent;
      border: solid 1px #d6d6d6;
      border-radius: 8px;
    }

    :global(.ant-input-group-addon) {
      background-color: transparent;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: solid 1px #d6d6d6 !important;
      border-left: none !important;

      button {
        padding: 0 10px;
        border: none;
        background-color: transparent !important;
      }
    }
  }

  :global(.ant-form-horizontal) {
    :global(.ant-form-item) {
      margin-bottom: 0;
    }

    :global(.ant-form-item-control) {
      text-align: right;
    }
  }

  :global(.ant-modal-content) {
    border-radius: 8px;
    overflow: hidden;

    :global(.ant-modal-close) {
      margin-top: 3px;
    }

    :global(.ant-modal-body) {
      border-radius: 8px;
      padding: 24px;
      background: #fff;

      :global(.ant-modal-confirm-btns) {
        margin-top: 24px;
      }

      button:global(.ant-btn-lg) {
        // min-width: 124px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding: 8px 16px;
        height: 36px;
        border-radius: 8px;
        color: #262626;
        border: solid 1px #d6d6d6;

        &:global(.ant-btn-primary) {
          color: #fff;
          background-color: #3578f7 !important;
          border: solid 1px #3578f7 !important;
        }

        &[disabled] {
          color: #fff;
          background-color: #aaa8 !important;
          border: solid 1px #aaa8 !important;
        }
      }

      button:global(.ant-btn-text) {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #3578f7 !important;

        &[disabled] {
          background-color: #aaa8 !important;
          border: none;
        }
      }
    }
  }

  :global(.abs_L_B) {
    position: absolute;
    left: 24px;
    bottom: 24px;
  }

  :global(.perfect-list.list-dk-scroll-view) {
    max-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    border: none;

    &:global(.border) {
      border: solid 1px #d6d6d6;
      border-radius: 8px;
      padding: 0 2px 0 10px;

      > div:global(.scroll-content) {
        padding-right: 8px;
      }
    }

    > div:global(.scroll-content) {
      padding-right: 0;
      height: 100%;
    }

    // :global(.ps__rail-x) {
    //   display: none;
    // }
  }

  :global(.channelList) :global(.perfect-list) {
    max-height: 396px;
    height: 396px;
  }

  .selectedMembers {
    display: none;
    position: absolute;
    left: calc(100% + 1px);
    top: 0;
    width: 256px;
    bottom: 0;
    border-radius: 8px;
    background-color: #fff;
    padding: 16px 10px;
  }

  &:global(.selectedVisible) {
    :global(.ant-modal) {
      min-width: 841px;
    }

    :global(.ant-modal-content) {
      overflow: visible;
      width: 540px;
    }

    .selectedMembers {
      display: block;

      :global(.selectedTitle) {
        position: relative;
        font-size: 14px;
        color: #646464;
        height: 36px;
        padding-bottom: 0;
        margin-bottom: 10px;

        .unSelectAll {
          position: absolute;
          top: 3px;
          right: 0;
          padding: 4px 2px;
          color: #3478f6 !important;
        }
      }

      :global(.selectedTitle) + :global(.ant-modal-confirm-body) {
        height: calc(100% - 46px);

        :global(.ant-modal-confirm-content) {
          height: 100%;

          :global(.perfect-list) {
            max-height: 100%;
          }
        }
      }

      :global(.perfect-list) {
        border: none;
      }

      :global(.ant-tag) {
        :global(.ant-tag-close-icon) {
          display: none;
        }

        &:hover {
          :global(.ant-tag-close-icon) {
            display: block;
          }
        }
      }
    }
  }
}

.channelsPopup {
  :global(.popupTitle) {
    position: relative;
    width: calc(100% - 40px);

    :global(.extraBtn) {
      position: absolute;
      top: calc(50% - 16px);
      right: 0;

      color: #fff;
    }
  }

  :global(.ant-modal-confirm-content) {
    position: relative;
  }

  .newChannelBtn {
    float: left;
  }
}

.roomSetting {
  .exitRoomBtn {
    position: absolute;
    left: 24px;
    bottom: 35px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    height: auto;
    color: #f04f4f;
    padding: 0;
    vertical-align: middle;

    > img {
      margin-right: 8px;
    }
  }

  .imgContainer :global(.kit__utils__avatar) {
    width: 100%;
    height: 100%;
  }
}

.memberList.shortType {
  height: 36px;
  margin-bottom: 10px;

  .member {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-top: 2px;
    margin-right: -4px;
    @include squircle-mask;

    img {
      width: 32px;
      height: 32px;
    }
  }

  .desc {
    margin-left: 12px;
    line-height: 18px;
    vertical-align: middle;

    :global(.nameWrap) {
      display: inline-block;
      max-width: calc(100% - 285px);
      height: 18px;
      vertical-align: middle;

      :global(.name) {
        @include ellipsis(1);
      }
    }

    &:global(.singleMember) :global(.nameWrap) {
      max-width: calc(100% - 140px);
    }
  }

  .extraBtn {
    float: right;
    min-width: 52px;

    &:global(.ant-btn.ant-btn-default) {
      color: #56595d;
    }

    :global(.anticon) {
      position: absolute;
      top: 5px;
    }
  }
}

.selectMember {
  :global(.perfect-list .ps__rail-x) {
    display: none;
  }

  .subTitle + :global(.perfect-list) {
    max-height: 396px;
    height: 396px;
  }

  // &.type2 {
  //   .membersWrap.selected :global(.ant-tag) {
  //     position: relative;
  //     display: inline-flex;
  //     flex-direction: column;
  //     vertical-align: top;
  //     width: 60px;
  //     height: 80px;
  //     padding: 5px;

  //     .avatar {
  //       position: relative;
  //       left: 5px;
  //       top: 0;
  //       width: 40px;
  //       height: 40px;
  //       border: solid 1px #8888;
  //       border-radius: 50%;
  //     }

  //     .name {
  //       margin-top: 5px;
  //       width: 100%;
  //       max-width: 100%;
  //       color: #000;
  //       text-align: center;
  //     }

  //     .email {
  //       display: none;
  //     }

  //     &:global(.closable) :global(.ant-tag-close-icon) {
  //       position: absolute;
  //       top: 2px;
  //       right: 2px;
  //       width: 16px;
  //       height: 16px;
  //       border-radius: 50%;

  //       > span,
  //       > span > svg {
  //         width: 100%;
  //         height: 100%;
  //         color: #000;
  //       }
  //     }
  //   }

  //   :global(.ant-tag.edit-tag.member):first-child {
  //     margin: 4px 0;
  //   }
  // }

  .subTitle {
    display: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin: 15px 0;
  }

  :global(.site-tag-plus) {
    position: relative;
    padding: 10px 10px 10px 42px;
    height: 56px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000;
    border: none;
    background-color: transparent;
    vertical-align: middle;
    display: flex;
    align-items: center;

    span[role='img'] {
      position: absolute;
      left: 0;
      top: calc(50% - 14px);
      width: 28px;
      height: 28px;
      border-radius: 8px;
      background: #f4f4f4;
      overflow: hidden;

      > svg {
        margin-top: 6px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .searchInput {
    :global(.ant-select-auto-complete) + button {
      height: 40px;
      vertical-align: top;
      margin-left: 2px;
    }
  }
}

.popupBase :global(.ant-checkbox-wrapper) {
  position: absolute;
  right: 0;
  top: calc(50% - 12px);
  width: 24px;
  height: 24px;

  // border: solid 1px #e5e6e7;

  :global(.ant-checkbox) {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
  }

  :global(.ant-checkbox-checked) {
    content: none;

    :global(.ant-checkbox) {
      top: 0;
    }

    :global(.ant-checkbox-inner) {
      &:after {
        content: url('/assets/images/icon-checked.svg');
      }
    }
  }

  :global(.ant-checkbox-inner) {
    background-color: transparent;
    border: none;

    &:after {
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      transform: none;
      opacity: 1;
      content: url('/assets/images/icon-unchecked.svg');
    }
  }
}

:global(.ant-tag.edit-tag.member) {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

:global(.ant-tag.edit-tag.member),
:global(.ant-list-item.edit-tag.member) {
  position: relative;
  display: flex;
  margin: 4px 0;
  padding: 10px 0;
  height: 36px;
  font-size: 16px;
  border: none;
  background-color: transparent;

  &:hover {
    background-color: #fbfbfb;
  }

  &:first-child {
    margin-top: 0;
  }

  &:global(.selectable) {
    padding-right: 30px;
  }

  :global(.anticon-close) {
    position: absolute;
    right: 10px;
    top: calc(50% - 5px);
  }

  :global(.ant-tag-close-icon) {
    position: absolute;
    right: 0;
    top: 6px;
    width: 24px;
    height: 24px;
    margin: 0;

    :global(.anticon) {
      top: 0;
    }
  }

  &:global(.ant-tag-checkable-checked):after {
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
    transform: none;
    opacity: 1;
    content: url('/assets/images/button-check.svg');
  }

  :global(.highlight) {
    color: red;
  }

  .memberItem {
    position: relative;
    padding: 0 10px 0 32px;
    flex: auto;

    > .avatar {
      position: absolute;
      left: 0;
      top: calc(50% - 12px);
      width: 24px;
      height: 24px;
      border-radius: 16px;
      overflow: hidden;
      @include squircle-mask;

      img {
        width: 100%;
        height: 100%;
        vertical-align: top;
      }
    }

    > .info {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;

      > .name {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #262626;
        max-width: calc(50% - 20px);
        vertical-align: middle;
        margin-right: 8px;

        > span {
          white-space: normal;
          @include ellipsis(1);
        }

        &.noEmail {
          max-width: calc(100% - 40px);
        }
      }

      > .email {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #b9babc;
        vertical-align: middle;

        > span {
          white-space: normal;
          @include ellipsis(1);
        }
      }
    }
  }

  :global(.ant-list-item-action) {
    display: flex;
    align-items: center;
    margin-left: 10px;

    :global(.memberType) {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #b9babc;
    }
  }

  &:global(.closable) {
    > .name {
      max-width: calc(100% - 15px);
    }

    > .email {
      max-width: calc(100% - 15px);
    }
  }
}

.searchPopup {
  :global(.highlight) {
    font-weight: bold;
    color: red;
  }
}

.newChatMenu {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  .newChatBtn:global(.ant-btn) {
    background-color: #3578f7;
    border-radius: 8px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    vertical-align: top;
    padding: 9px 15px;

    > span {
      vertical-align: top;
      height: 20px;
      line-height: 20px;
    }

    @include respond-to('mobile') {
      padding: 9px;

      :global(.anticon) + span {
        display: none;
      }
    }
  }
}

.replyOrigin {
  display: inline-block;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  padding: 0 0 3px;
  margin: 0 0 -2px;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  border-bottom: solid 1px #d9d9d9;

  &.closable {
    padding-right: 30px;
  }

  .replyContainer {
    text-align: left;
    border-left: none;
    padding: 0;
    color: inherit;

    .rHeader {
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: inherit;

      .rName {
        @include ellipsis(1);
      }

      .time {
        // display: inline-block;
        display: none;
        font-size: 10px;
        position: relative;
        text-align: left;
        left: auto;
        top: auto;
        right: auto;
        bottom: auto;
        margin-left: 10px;
        color: gray;
      }
    }

    .rBody {
      font-size: 14px;
      line-height: 18px;
      @include ellipsis(3);
    }

    .attachments {
      width: 100%;

      > span {
        display: inline-block;
        overflow: hidden;
        white-space: pre;
        vertical-align: middle;
      }

      span.front {
        text-overflow: ellipsis;
      }

      span.end {
        text-overflow: '';
      }
    }
  }

  .closeBtn {
    position: absolute;
    top: 6px;
    right: 4px;
    border: none;
    background-color: transparent;
    color: #000;
  }
}

.message.answer .replyContainer {
  .rBody {
    color: #7e7e7e;
  }

  .attachments {
    color: #7e7e7e;
  }
}

.fileView {
  position: relative;
  height: 100%;
  padding: 0 8px;

  :global(.searchWrapper) {
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    z-index: 1;
  }

  :global(.fileList) {
    padding-top: 44px;
  }
}

:global(.fileList) {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;

  > :global(.ant-btn) {
    margin-top: 5px;
    width: 100%;
  }

  .filesWrap:global(.perfect-list) {
    height: 100%;
    min-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;

    :global(.ps__rail-x) {
      display: none;
    }
  }

  .spin {
    position: absolute;
    width: calc(100% - 10px);
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(230, 230, 230, 70%);
    transition: background-color 0.5s;

    > * {
      position: relative;
      top: calc(50% - 70px);
    }
  }

  .refreshBtn {
    height: 32px;
    margin-top: 10px;

    :global(.anticon) {
      width: 100%;
      height: 100%;
      top: 0;
      align-items: center;
    }
  }

  :global(.perfect-list) {
    height: calc(100% - 52px);

    :global(.scroll-content) {
      height: 100%;
    }

    :global(.ps__rail-y) {
      width: 6px;
    }
  }

  .listContainer {
    :global(.ant-list-header) {
      padding: 4px 0;

      button {
        width: 100%;
        height: 100%;
      }
    }

    :global(.ant-list-item) {
      padding: 8px 8px 8px 0;

      &:hover {
        :global(.downloadBtn) {
          display: inline-block;
        }
      }

      :global(.ant-list-item-meta) {
        align-items: center;
      }

      :global(.ant-list-item-meta-avatar) {
        position: relative;
        height: 100%;
        margin-right: 2px;
        padding: 0 8px;
      }

      :global(.ant-list-item-meta-title) {
        margin-bottom: 2px;
        font-size: 14px;
        color: #262626;
        @include ellipsis(1);
      }

      :global(.ant-list-item-meta-description) {
        font-size: 12px;
        color: #9b9b9b;
        @include ellipsis(3);
      }

      :global(.ant-btn) {
        padding: 0;
        width: 24px;
        height: 24px;
        line-height: 24px;
        vertical-align: top;
        border: none;
        border-radius: 0;
        margin-left: 12px;
      }

      :global(.downloadBtn) {
        display: none;
      }
    }

    .fileName {
      width: 100%;

      > span {
        display: inline-block;
        overflow: hidden;
        white-space: pre;
        vertical-align: middle;
      }

      span.front {
        text-overflow: ellipsis;
      }

      span.end {
        text-overflow: '';
      }
    }

    .fileDesc {
      line-height: 14px;
      @include ellipsis(1);
    }
  }

  .fileIcon {
    position: relative;
    top: calc(50% - 16px);
    width: 32px;
    height: 32px;
    border-radius: 4px;
    overflow: hidden;

    > svg {
      width: 100%;
      height: 100%;
    }

    > img {
      width: 100%;
      height: 100%;
    }
  }

  &:global(.imageType) {
    :global(.perfect-list) {
      max-height: 286px;
      height: 286px;

      .scrollHelper {
        width: calc(100% - 7px);
      }
    }

    :global(.ant-col .ant-list-item) {
      height: 143px;
      margin-bottom: 0;

      :global(.ant-list-item-meta) {
        display: block;
        width: 100%;
        height: 100%;

        :global(.ant-list-item-meta-avatar) {
          padding: 1px;
          margin: 0;

          .fileIcon {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
              object-fit: cover;
            }
          }
        }

        :global(.ant-list-item-meta-content) {
          display: none;
        }
      }
    }
  }
}

:global(.roomIcon) {
  @include squircle-mask;

  img:global(.photo) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.roomIconSpin:global(.ant-spin) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  > :global(.ant-spin-dot) {
    top: calc(50% - 10px);
  }
}

.imgContainer {
  position: relative;

  .img {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    border: solid 1px #eaeaea;
    text-align: center;
    vertical-align: bottom;
    margin-right: 10px;
    overflow: hidden;
    @include squircle-mask;

    > span:global(.fe) {
      display: inline-block;
      margin-top: 6px;
      font-size: 80px;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .spin {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -13px;
      margin-left: -10px;
    }
  }

  button {
    width: 113px;
    height: 43px;
    border-radius: 4px;
    border: solid 1px #9fa7c1;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #9fa7c1;
  }
}

:global(.ant-tabs.singleTab) {
  > :global(.ant-tabs-nav) {
    display: none;
  }

  :global(.ant-tabs-content) {
    position: static;
  }
}

@include respond-to('desktop') {
  .chatMember .leftBox > .backBtn {
    display: none;
  }
}

@include respond-to('empty-320') {
  .messageWrapper > .message {
    &.send {
      padding-right: 5px !important;

      .messageContent {
        max-width: calc(100% - 50px);
      }
    }

    &.answer {
      padding-left: 5px;
      padding-top: 22px;
      flex-direction: column-reverse;

      &.skipHeader {
        padding-top: 0;
      }

      .messageContent {
        margin-left: 28px;
        margin-top: 3px;
        max-width: calc(100% - 64px);

        + .messageAvatar {
          position: absolute;
          left: 5px;
          top: 26px;
          width: 24px;
          height: 24px;
          padding-top: 0;

          i {
            font-size: 20px;
          }
        }
      }

      .header {
        left: 35px;
        top: 0;
      }
    }
  }

  .linkPreview {
    font-size: 10px !important;
    min-height: unset !important;
    border-radius: 4px;
    max-height: unset;
    height: auto;

    .previewThumb {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 40px;
      height: 40px;
      min-width: unset;

      img {
        border-radius: 4px;
        width: 100%;
        height: auto;
        object-fit: unset;
      }
    }

    .info {
      padding: 5px;

      .urlInfo {
        padding-left: 0;
        margin-top: 0.2em;
        font-size: 10px;
      }

      .title {
        padding-left: 48px;
        margin: 0;
        min-height: 40px;
      }

      p {
        padding-top: 0.5em;
        font-size: 10px;
        line-height: 14px;
        min-height: unset;
      }
    }
  }

  .emojiPopup {
    :global(.ant-modal-body) > div {
      width: 281.6px;
      height: 348px;

      > em-emoji-picker {
        transform: scale(0.8);
        transform-origin: left top;
      }
    }
  }

  :global(.floatingMenu) {
    :global(.ant-dropdown-menu-item) {
      .floatingItemWrap {
        padding: 6px 8px;
      }
    }
  }
}
