@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~simplebar/dist/simplebar.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/bootstrap/style';
@import 'components/kit/vendors/perfect-scrollbar/style';
@import 'components/kit/vendors/nprogress/style';
@import 'components/kit/vendors/chartist/style';
@import 'components/kit/vendors/chartist-tooltip-plugin/style';
@import 'components/kit/vendors/jvectormap/style';
@import 'components/kit/vendors/react-sortable-tree/style';
@import 'components/kit/vendors/react-draft-wysiwyg/style';

@import 'components/kit/core/style'; // Clean UI KIT styles
@import 'components/cleanui/styles/style'; // Clean UI styles

// @import 'scss/main'; // 디자인 적용.

@import 'gql/ninegqlUI/style';

body {
  overflow-y: scroll;
}

.empty-layout {
  height: 100vh;
}

.router-loading {
  position: relative;
  width: 100%;
  height: 100%;

  .ant-spin {
    position: absolute;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
  }
}

[data-kit-theme='default'] .ant-radio {
  vertical-align: middle;
}

[data-kit-theme='default'] .ant-form-item {
  &.no-bottom-margin {
    margin-bottom: 0;
  }

  .ant-form-item-extra {
    margin-top: 5px;
    margin-left: 10px;
  }
}

.ant-table-expanded-row-fixed {
  width: auto !important;
}

.hidden {
  display: none !important;
}

.ant-modal.code-view {
  width: 1220px !important;
  max-width: 90%;
  height: 85%;

  .ant-modal-content {
    height: 100%;

    .ant-modal-body {
      height: calc(100% - 108px);
      overflow: auto;

      pre {
        overflow: visible;
      }
    }
  }
}

.ngql-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.simplebar-scrollbar:before {
  background: lightgray;
}

.rightBtns {
  float: right;
  text-align: right;

  .ant-btn {
    background-color: #3578f7;
    border-radius: 8px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    vertical-align: top;
    padding: 9px 15px;
  }
}
